<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" :title="fromTitle" @close="onClose">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="设备类型" prop="deviceType">
        <a-select allowClear placeholder="请选择设备类型" v-model="form.deviceType">
          <a-select-option v-for="d in deviceTypeOptions" :key="d.dictValue" :value="d.dictValue">{{
            d.dictLabel
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="车辆编号" prop="vehicleId" v-if="form.deviceType === '1'">
        <a-select
          show-search
          allowClear
          placeholder="请输入车辆编号模糊查找"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          @search="handleVehicleNoSearch"
          @change="handleVehicleNoChange"
          :loading="loading"
          v-model="form.vehicleId"
        >
          <a-select-option v-for="(d, index) in vehicleNoArray" :key="index" :value="d.vehicleNo">
            {{ d.vehicleNo }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="车牌号" prop="plateNum" v-if="form.deviceType === '1'">
        <a-select
          show-search
          allowClear
          placeholder="请输入车牌号模糊查找"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          @search="handleLicensePlateNumSearch"
          @change="handleLicensePlateNumChange"
          :loading="loading"
          v-model="form.plateNum"
        >
          <a-select-option v-for="(d, index) in vehicleNoArray" :key="index" :value="d.licensePlateNum">
            {{ d.licensePlateNum }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="人员名称" prop="employeeName" v-if="form.deviceType === '2'">
        <a-select
          show-search
          placeholder="请输入人员名称查找"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          @search="handlePrincipalsSearch"
          @change="handlePrincipalsChange"
          :loading="loading"
          v-model="form.employeeName"
        >
          <a-select-option v-for="d in personnelArray" :key="d.id">{{ d.name }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <!-- <a-form-model-item label="数据类型" prop="dataType">
        <a-select allowClear placeholder="请选择数据类型" v-model="form.dataType">
          <a-select-option v-for="d in deviceDataTypeOptions" :key="d.dictValue" :value="d.dictValue">{{
            d.dictLabel
          }}</a-select-option>
        </a-select>
      </a-form-model-item> -->
      <a-form-model-item label="设备名称" prop="deviceName">
        <a-input v-model="form.deviceName" placeholder="请输入设备名称" />
      </a-form-model-item>
      <a-form-model-item label="设备卡号" prop="deviceCard">
        <a-input v-model="form.deviceCard" placeholder="请输入设备卡号" />
      </a-form-model-item>
      <a-form-model-item label="设备编码" prop="originEquipmentCode" v-if="form.deviceType === '1'">
        <a-input v-model="form.originEquipmentCode" placeholder="请输入原始设备编码" />
      </a-form-model-item>
      <a-form-model-item label="IMEI" prop="imei">
        <a-input v-model="form.imei" placeholder="请输入IMEI" />
      </a-form-model-item>
      <!-- <a-form-model-item label="雇员id" prop="employeeId">
        <a-input v-model="form.employeeId" placeholder="请输入雇员id" />
      </a-form-model-item> -->

      <!-- <a-form-model-item label="是否上传数据" prop="uploadDataStatus">
        <a-radio-group v-model="form.uploadDataStatus" button-style="solid">
          <a-radio-button v-for="d in statusOptions" :key="d.dictValue" :value="d.dictValue">{{
            d.dictLabel
          }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="是否转码" prop="transcodeStatus">
        <a-radio-group v-model="form.transcodeStatus" button-style="solid">
          <a-radio-button v-for="d in statusOptions" :key="d.dictValue" :value="d.dictValue">{{
            d.dictLabel
          }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="使用年限(年)" prop="serviceLife">
        <a-input v-model="form.serviceLife" placeholder="请输入使用年限(年)" />
      </a-form-model-item>
      <a-form-model-item label="生产厂商" prop="manufacturer">
        <a-input v-model="form.manufacturer" placeholder="请输入生产厂商" />
      </a-form-model-item>
      <a-form-model-item label="厂商联系人" prop="manufacturerContact">
        <a-input v-model="form.manufacturerContact" placeholder="请输入厂商联系人" />
      </a-form-model-item>
      <a-form-model-item label="联系电话" prop="contactPhone">
        <a-input v-model="form.contactPhone" placeholder="请输入联系电话" />
      </a-form-model-item> -->
      <a-form-model-item label="有效时间" prop="effectiveTime">
        <a-date-picker
          style="width: 100%"
          v-model="form.effectiveTime"
          value-format="YYYY-MM-DD HH:mm:ss"
          format="YYYY-MM-DD HH:mm:ss"
          allow-clear
        />
      </a-form-model-item>
      <!-- <a-form-model-item label="固件类型" prop="firmwareType">
        <a-select placeholder="请选择固件类型" v-model="form.firmwareType">
          <a-select-option v-for="d in firmwareTypeOptions" :key="d.dictValue" :value="d.dictValue">{{
            d.dictLabel
          }}</a-select-option>
        </a-select>
      </a-form-model-item> -->
      <a-form-model-item label="是否有效" prop="status">
        <a-radio-group v-model="form.status" button-style="solid">
          <a-radio-button v-for="d in statusOptions" :key="d.dictValue" :value="d.dictValue">{{
            d.dictLabel
          }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getRaDevice, addRaDevice, updateRaDevice } from '@/api/iot/raDevice'
import { searchCarNo } from '@/api/iot/vehicleAccount'
import { listEmployeeAccount } from '@/api/iot/employeeAccount'

export default {
  name: 'CreateForm',
  props: {
    statusOptions: {
      type: Array,
      default: () => []
    },
    deviceTypeOptions: {
      type: Array,
      default: () => []
    },
    deviceDataTypeOptions: {
      type: Array,
      default: () => []
    }
  },
  components: {},
  data() {
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        plateNum: undefined,
        vehicleId: undefined,
        deviceType: undefined,
        dataType: undefined,
        deviceName: null,
        deviceCard: null,
        imei: null,
        employeeId: null,
        uploadDataStatus: '0',
        originEquipmentCode: null,
        transcodeStatus: '0',
        serviceLife: null,
        manufacturer: null,
        manufacturerContact: null,
        contactPhone: null,
        effectiveTime: null,
        firmwareType: null,
        employeeName: undefined,
        status: '1'
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        deviceType: [{ required: true, message: '设备类型不能为空', trigger: 'blur' }],
        deviceCard: [{ required: true, message: '设备卡号不能为空', trigger: 'blur' }],
        effectiveTime: [{ required: true, message: '生效时间不能为空', trigger: 'blur' }]
      },
      vehicleNoArray: [],
      firmwareTypeOptions: [],
      personnelArray: []
    }
  },
  filters: {},
  created() {
    this.getData()
  },
  computed: {},
  watch: {
    'form.deviceType': function(val) {
      console.log('watch_deviceType', val)
      if (val === '2') {
        this.rules = {
          deviceType: [{ required: true, message: '设备类型不能为空', trigger: 'change' }],
          deviceCard: [{ required: true, message: '设备卡号不能为空', trigger: 'blur' }],
          effectiveTime: [{ required: true, message: '有效时间不能为空', trigger: 'change' }],
          imei: [{ required: true, message: 'IMEI不能为空', trigger: 'blur' }],
          employeeName: [{ required: true, message: '人员名称不能为空', trigger: 'change' }]
        }
      } else {
        this.rules = {
          deviceType: [{ required: true, message: '设备类型不能为空', trigger: 'change' }],
          deviceCard: [{ required: true, message: '设备卡号不能为空', trigger: 'blur' }],
          effectiveTime: [{ required: true, message: '有效时间不能为空', trigger: 'change' }],
          vehicleId: [{ required: true, message: '车辆编号不能为空', trigger: 'blur' }],
          plateNum: [{ required: true, message: '车牌号不能为空', trigger: 'blur' }],
          originEquipmentCode: [{ required: true, message: '设备编码不能为空', trigger: 'blur' }]
        }
      }
    }
  },
  mounted() {},
  methods: {
    async getData() {
      // 由于列表需要字典数据，所以要先获取到字典数据，再获取列表
      await this.getDictData()
    },
    async getDictData() {
      this.loading = true
      await this.getDicts('iot_device_firmware_type').then(response => {
        this.firmwareTypeOptions = response.data
      })
      this.loading = false
    },
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        vehicleId: undefined,
        plateNum: undefined,
        deviceType: undefined,
        dataType: undefined,
        deviceName: null,
        deviceCard: null,
        imei: null,
        employeeId: null,
        uploadDataStatus: '0',
        originEquipmentCode: null,
        transcodeStatus: '0',
        serviceLife: null,
        manufacturer: null,
        manufacturerContact: null,
        contactPhone: null,
        employeeName: undefined,
        effectiveTime: null,
        firmwareType: undefined,
        status: '1'
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      const id = row ? row.id : ids
      console.log('row....~', row)
      getRaDevice(id).then(response => {
        response.data.employeeName = row.employeeName
        this.form = response.data
        for (const key in this.form) {
          if (typeof this.form[key] === 'number') {
            this.form[key] = this.form[key] + ''
          }
        }
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updateRaDevice(this.form).then(response => {
              this.$message.success('修改成功', 3)
              this.open = false
              this.$emit('ok')
            })
          } else {
            addRaDevice(this.form).then(response => {
              this.$message.success('新增成功', 3)
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    },
    handlePrincipalsSearch(value) {
      console.log('handlePersonnelSearch', value)
      const queryParam = {
        name: value
      }
      this.loading = true
      listEmployeeAccount(queryParam).then(response => {
        this.personnelArray = response.rows
        this.loading = false
      })
    },
    handlePrincipalsChange(value, option) {
      console.log('options=======:{}', option)
      console.log('value=======:{}', value)
      this.form.employeeId = value
      const tmpArray = this.personnelArray.filter(p => p.id === value)
      console.log('tmpArray=======:{}', tmpArray)
      if (tmpArray.length > 0) {
        this.form.employeeName = tmpArray[0].name
        this.form.employeeId = tmpArray[0].id
        this.$forceUpdate()
      }
    },
    handleVehicleNoSearch(value) {
      console.log('handleVehicleNoSearch', value)
      const queryParam = {
        vehicleNo: value
      }
      this.loading = true
      searchCarNo(queryParam).then(response => {
        this.vehicleNoArray = response
        this.loading = false
      })
    },
    handleVehicleNoChange(value) {
      this.form.vehicleId = value
      // 设置车牌号
      this.$forceUpdate()
      const tmpArray = this.vehicleNoArray.filter(p => p.vehicleNo === value)
      console.log('handleVehicleNoChange', this.vehicleNoArray)
      if (tmpArray.length > 0) {
        console.log('handleVehicleNoChange', tmpArray)
        this.form.plateNum = tmpArray[0].licensePlateNum
        this.$forceUpdate()
      }
    },
    handleLicensePlateNumSearch(value) {
      console.log('handleLicensePlateNumSearch', value)
      const queryParam = {
        licensePlateNum: value
      }
      this.loading = true
      searchCarNo(queryParam).then(response => {
        this.vehicleNoArray = response
        this.loading = false
      })
    },
    handleLicensePlateNumChange(value) {
      this.form.plateNum = value
      // 设置车辆自编号
      const tmpArray = this.vehicleNoArray.filter(p => p.licensePlateNum === value)
      if (tmpArray.length > 0) {
        this.form.vehicleId = tmpArray[0].vehicleNo
        this.$forceUpdate()
      }
    }
  }
}
</script>

/*
 * @Auth: linjituan
 * @Date: 2021-10-19 16:14:26
 * @LastEditors: linjituan
 * @LastEditTime: 2021-10-20 18:52:41
 */
import request from '@/utils/request'

// 获取部门下拉树及人员设备
export function getDeviceTree(parameter) {
  return request({
    url: '/iot/raDevice/tree',
    method: 'get',
    params: parameter
  })
}

// 查询设备 列表
export function listRaDevice(query) {
  return request({
    url: '/iot/raDevice/list',
    method: 'get',
    params: query
  })
}

// 查询设备 详细
export function getRaDevice(id) {
  return request({
    url: '/iot/raDevice/' + id,
    method: 'get'
  })
}

// 新增设备
export function addRaDevice(data) {
  return request({
    url: '/iot/raDevice',
    method: 'post',
    data: data
  })
}

// 修改设备
export function updateRaDevice(data) {
  return request({
    url: '/iot/raDevice',
    method: 'put',
    data: data
  })
}

// 删除设备
export function delRaDevice(id) {
  return request({
    url: '/iot/raDevice/' + id,
    method: 'delete'
  })
}

// 导出设备
export function exportRaDevice(query) {
  return request({
    url: '/iot/raDevice/export',
    method: 'get',
    params: query
  })
}

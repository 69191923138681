var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.fromTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "设备类型", prop: "deviceType" } },
            [
              _c(
                "a-select",
                {
                  attrs: { allowClear: "", placeholder: "请选择设备类型" },
                  model: {
                    value: _vm.form.deviceType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "deviceType", $$v)
                    },
                    expression: "form.deviceType",
                  },
                },
                _vm._l(_vm.deviceTypeOptions, function (d) {
                  return _c(
                    "a-select-option",
                    { key: d.dictValue, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm.form.deviceType === "1"
            ? _c(
                "a-form-model-item",
                { attrs: { label: "车辆编号", prop: "vehicleId" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        "show-search": "",
                        allowClear: "",
                        placeholder: "请输入车辆编号模糊查找",
                        "default-active-first-option": false,
                        "show-arrow": false,
                        "filter-option": false,
                        "not-found-content": null,
                        loading: _vm.loading,
                      },
                      on: {
                        search: _vm.handleVehicleNoSearch,
                        change: _vm.handleVehicleNoChange,
                      },
                      model: {
                        value: _vm.form.vehicleId,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "vehicleId", $$v)
                        },
                        expression: "form.vehicleId",
                      },
                    },
                    _vm._l(_vm.vehicleNoArray, function (d, index) {
                      return _c(
                        "a-select-option",
                        { key: index, attrs: { value: d.vehicleNo } },
                        [_vm._v(" " + _vm._s(d.vehicleNo) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.form.deviceType === "1"
            ? _c(
                "a-form-model-item",
                { attrs: { label: "车牌号", prop: "plateNum" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        "show-search": "",
                        allowClear: "",
                        placeholder: "请输入车牌号模糊查找",
                        "default-active-first-option": false,
                        "show-arrow": false,
                        "filter-option": false,
                        "not-found-content": null,
                        loading: _vm.loading,
                      },
                      on: {
                        search: _vm.handleLicensePlateNumSearch,
                        change: _vm.handleLicensePlateNumChange,
                      },
                      model: {
                        value: _vm.form.plateNum,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "plateNum", $$v)
                        },
                        expression: "form.plateNum",
                      },
                    },
                    _vm._l(_vm.vehicleNoArray, function (d, index) {
                      return _c(
                        "a-select-option",
                        { key: index, attrs: { value: d.licensePlateNum } },
                        [_vm._v(" " + _vm._s(d.licensePlateNum) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.form.deviceType === "2"
            ? _c(
                "a-form-model-item",
                { attrs: { label: "人员名称", prop: "employeeName" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        "show-search": "",
                        placeholder: "请输入人员名称查找",
                        "default-active-first-option": false,
                        "show-arrow": false,
                        "filter-option": false,
                        "not-found-content": null,
                        loading: _vm.loading,
                      },
                      on: {
                        search: _vm.handlePrincipalsSearch,
                        change: _vm.handlePrincipalsChange,
                      },
                      model: {
                        value: _vm.form.employeeName,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "employeeName", $$v)
                        },
                        expression: "form.employeeName",
                      },
                    },
                    _vm._l(_vm.personnelArray, function (d) {
                      return _c("a-select-option", { key: d.id }, [
                        _vm._v(_vm._s(d.name)),
                      ])
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-model-item",
            { attrs: { label: "设备名称", prop: "deviceName" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入设备名称" },
                model: {
                  value: _vm.form.deviceName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "deviceName", $$v)
                  },
                  expression: "form.deviceName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "设备卡号", prop: "deviceCard" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入设备卡号" },
                model: {
                  value: _vm.form.deviceCard,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "deviceCard", $$v)
                  },
                  expression: "form.deviceCard",
                },
              }),
            ],
            1
          ),
          _vm.form.deviceType === "1"
            ? _c(
                "a-form-model-item",
                { attrs: { label: "设备编码", prop: "originEquipmentCode" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入原始设备编码" },
                    model: {
                      value: _vm.form.originEquipmentCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "originEquipmentCode", $$v)
                      },
                      expression: "form.originEquipmentCode",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-model-item",
            { attrs: { label: "IMEI", prop: "imei" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入IMEI" },
                model: {
                  value: _vm.form.imei,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "imei", $$v)
                  },
                  expression: "form.imei",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "有效时间", prop: "effectiveTime" } },
            [
              _c("a-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  "value-format": "YYYY-MM-DD HH:mm:ss",
                  format: "YYYY-MM-DD HH:mm:ss",
                  "allow-clear": "",
                },
                model: {
                  value: _vm.form.effectiveTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "effectiveTime", $$v)
                  },
                  expression: "form.effectiveTime",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "是否有效", prop: "status" } },
            [
              _c(
                "a-radio-group",
                {
                  attrs: { "button-style": "solid" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                _vm._l(_vm.statusOptions, function (d) {
                  return _c(
                    "a-radio-button",
                    { key: d.dictValue, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }